<template>
  <DashboardTemplate :sections="sections" :grid-area-component="gridAreaComponentDE" :grid-rows="1">
    <template #navbarItems>
      <Breadcrumbs style="margin-left: 20px" :breadcrumb-array="breadcrumbInfo" />
    </template>
  </DashboardTemplate>
</template>
<script>
import DashboardTemplate from '@/components/dashboard/Template.vue';
import Breadcrumbs from '@/components/molecules/sections/Breadcrumbs.vue';

export default {
  name: 'DigitalEnrollment',
  components: { DashboardTemplate, Breadcrumbs },
  metaInfo() {
    return {
      title: `${this.$tc('dashboard.breadcrumbs.app_name')} | ${this.$tc('dashboard.breadcrumbs.applications')}`,
      meta: [{ name: 'robots', content: 'noindex, nofollow' }],
    };
  },
  data() {
    return {
      sections: [
        {
          name: 'dge-applications-panel',
          title: 'Mis Postulaciones',
          mobileHeight: 'calc(100vh - 70px)',
          showTitle: false,
          gridArea: 'dge-applications-panel',
        },
      ],
      breadcrumbInfo: [
        {
          text: 'digital_enrollment.breadcrumb',
          route: '/dashboard',
        },
      ],
      gridAreaComponentDE: '"dge-applications-panel dge-applications-panel dge-applications-panel dge-applications-panel dge-applications-panel dge-applications-panel" "dge-applications-panel dge-applications-panel dge-applications-panel dge-applications-panel dge-applications-panel dge-applications-panel" "dge-applications-panel dge-applications-panel dge-applications-panel dge-applications-panel dge-applications-panel dge-applications-panel" "dge-applications-panel dge-applications-panel dge-applications-panel dge-applications-panel dge-applications-panel dge-applications-panel"',
    };
  },
};
</script>
